import { useLoaderData, Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from "react";

import LastUpdate from '../SharedComponents/LastUpdate';
import BackButton from '../SharedComponents/BackButton';
import TviaAssetsSearch from './TviaAssetsSearch';

import '../MainData.css'

function TviaReports() {
    const tviaAssetData = useLoaderData();

    const refreshStatus = localStorage.getItem("refresh");
    useEffect(() => {
        if (refreshStatus === "on") {
            const interval = setInterval(() => {
                window.location.reload(false);
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [refreshStatus]);

    // Sort functions
    let tviaAssetFilteredData = tviaAssetData;
    const [sortValue, setSortValue] = useState("id");
    const [sortOrder, setSortOrder] = useState("");

    tviaAssetFilteredData.sort(dynamicSort(`${sortOrder}${sortValue}`));

    return (
        <div className='main-info'>
            <LastUpdate />
            <BackButton />
            <TviaAssetsSearch updateSortValue={setSortValue} updateSortOrder={setSortOrder} />

            <h2 className='subheading'>TVIA Assets</h2>
            <table>
                <thead>
                    <tr>
                        <th>Asset ID</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    {tviaAssetFilteredData.map((item) => {
                        return (
                            <tr key={`tr-${item.id}`}>
                                <th>{item.id}</th>
                                <td>{item.createdAt}</td>
                                <td>{item.updatedAt}</td>
                                <td><Link className="link-to-asset-details" to={`/components/tvia/assets/${item.id}/ports`}><span className="material-symbols-outlined open-report-button"> lan </span><span>Ports</span> </Link></td>
                                <td><Link className="link-to-asset-details" to={`/components/tvia/assets/${item.id}/services`}><span className="material-symbols-outlined open-report-button"> settings </span><span>Services</span></Link></td>
                                <td><Link className="link-to-asset-details" to={`/components/tvia/assets/${item.id}/vulnerabilities`}><span className="material-symbols-outlined open-report-button"> report </span><span>Vul/ties</span></Link></td>
                            </tr>)
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default TviaReports

export async function loader({ request }) {
    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    //const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    // Get the TVIA Reports
    const response = await fetch(`${baseURL}/api/tvia/assets`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    if (!response.ok) {
        // TODO: Handle the rerror
    }
    const responseData = await response.json();

    return responseData;
};

function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}