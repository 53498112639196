import { NavLink } from 'react-router-dom'

// Import CSS
import './NavItem.css'

function NavItem(props) {

    if (props.menuOpen) {
        return (
            <NavLink to={props.navItem["url"]} className={({ isActive }) => isActive ? "active-tool tools-nav-list-item__label" : "tools-nav-list-item__label"} onClick={props.navItem["onClick"]} end>
                <span className="material-symbols-outlined"> {props.navItem["logo"]} </span>
                <span>{props.navItem["name"]}</span>
                {props.navItem["extraLogo"].map(extraLogo => <span key="extraLogo" className="material-symbols-outlined">
                    {extraLogo}
                </span>)}
            </NavLink>
        )
    } else {
        return (
            <NavLink to={props.navItem["url"]} className={({ isActive }) => isActive ? "active-tool tools-nav-list-item__label" : "tools-nav-list-item__label"} onClick={props.navItem["onClick"]} end>
                <span className="material-symbols-outlined"> {props.navItem["logo"]} </span>
                {props.navItem["extraLogo"].map(extraLogo => <span key="extraLogo" className="material-symbols-outlined">
                    {extraLogo}
                </span>)}
            </NavLink>
        )
    }
}

export default NavItem;