import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

// Import Components
import NavItem from './NavItem';
import SecantComponentsMenu from './SecantComponentsMenu';

// Import CSS
import './MainNav.css'

function MainNav(props) {
    const [expandServices, setExpandServices] = useState(false);
    const servicesClickHandler = () => {
        setExpandServices(() => !expandServices);
    }
    const secantComponents = {
        name: "Components",
        logo: "terminal",
        extraLogo: [expandServices ? 'expand_more' : 'expand_less'],
        onClick: servicesClickHandler,
        url: null
    }
    const navBarItems = [
        {
            name: "Vocabulary",
            logo: "dictionary",
            extraLogo: [],
            onClick: null,
            url: "vocabulary"
        },
        {
            name: "Alerts",
            logo: "notification_important",
            extraLogo: [],
            onClick: null,
            url: "alerts"
        },
        {
            name: "Configuration",
            logo: "settings",
            extraLogo: [],
            onClick: null,
            url: "configuration"
        },
        {
            name: "Documentation",
            logo: "description",
            extraLogo: [],
            onClick: null,
            url: "documentation"
        },
        {
            name: "Home",
            logo: "home",
            extraLogo: [],
            onClick: null,
            url: "/home"
        },
    ]

    return (
        <nav className={`tools-nav ${props.menuOpen ? "tools-nav--long" : "tools-nav--short"}`}>
            <ul className={"tools-nav-list"}>
                <li className="tools-nav-list-item">
                    <SecantComponentsMenu navItem={secantComponents} menuOpen={props.menuOpen} />
                    <div className={`${expandServices ? '' : 'hide'}`}>
                        <ul className={`tools-nav-list-item__list ${props.menuOpen ? "tools-nav-list-item__list--long" : "tools-nav-list-item__list--short"}`}>
                            <li><NavLink to="components/tim" className={({ isActive }) => isActive ? "active-tool" : ""} end>TIM</NavLink></li>
                            <li><NavLink to="components/cocrae" className={({ isActive }) => isActive ? "active-tool" : ""} end>CoCRAE</NavLink></li>
                            <li><NavLink to="components/hva" className={({ isActive }) => isActive ? "active-tool" : ""} end>HVA</NavLink></li>
                            <li><NavLink to="components/tvia" className={({ isActive }) => isActive ? "active-tool" : ""} end>TVIA</NavLink></li>
                            <li><NavLink to="components/bae" className={({ isActive }) => isActive ? "active-tool" : ""} end>BAE</NavLink></li>
                            <li><NavLink to="components/apis" className={({ isActive }) => isActive ? "active-tool" : ""} end>API/CDS</NavLink></li>
                        </ul>
                    </div>
                </li>
                {navBarItems.map(navBarItem => <li key={navBarItem.url} className="tools-nav-list-item">
                    <NavItem navItem={navBarItem} menuOpen={props.menuOpen} />
                </li>)}
            </ul>
        </nav >
    )
}

export default MainNav;