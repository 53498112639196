import './MenuButton.css'

function MenuButton(props) {
    return (
        <div className='menu-button center'>
            <div className={`menu-button-container ${props.menuOpen ? "" : "active-tool"}`} onClick={props.menuToggleHandler}>
                <span className="tools-nav-btn material-symbols-outlined">
                    menu
                </span>
            </div>
        </div>
    )
}

export default MenuButton;