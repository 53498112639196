import { Form, useActionData } from 'react-router-dom';
import secantLogoFull from '../../img/secant_logo_small.png'

import './AuthForm.css'


function AuthForm() {
    const actionData = useActionData();

    return (
        <div className='auth-form-container'>
            <header className='auth-header'>
                <img
                    src={secantLogoFull}
                    alt="SECANT Logo Full"
                    className='auth-logo' />
                <div>
                    <h1 className='auth-heading-primary'>SECANT Dashboard</h1>
                    <h2 className='subheading'>For security professionals</h2>
                </div>
            </header>
            <Form className="auth-form" method='post'>
                <h1 className='heading-secondary'>Log in</h1>
                {actionData && actionData.error && <p className='subheading'>{actionData.error_description}</p>}
                <p>
                    <label htmlFor='username'>Username</label>
                    <input id='username' type='text' name='username' required />
                </p>
                <p>
                    <label htmlFor='password'>Password</label>
                    <input id='password' type='password' name='password' required />
                </p>
                <button className='btn'>Log In</button>
            </Form>
            <div className='auth-footer'>
                <span className="material-symbols-outlined">
                    info
                </span>
                <p>Secured by SECANT DIM</p>
            </div>
        </div>
    )
}

export default AuthForm;