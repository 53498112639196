import { useLoaderData } from 'react-router-dom'
import { useState } from "react";
import ReactPaginate from 'react-paginate';
import TimEventSearch from './TimEventsSearch';

import TimTable from './TimTable';
import LastUpdate from '../SharedComponents/LastUpdate';

import '../MainData.css'
import './TimData.css'

function TimData() {
    const timFetchedData = useLoaderData();
    const itemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("")

    let timFilteredEvents = timFetchedData;

    if (searchValue !== "") {
        let timFilteredEventsByID = timFetchedData.filter(event => event.eventId.startsWith(searchValue));
        let timFilteredEventsByName = timFetchedData.filter(event => event.eventName.startsWith(searchValue));
        timFilteredEvents = [...timFilteredEventsByID, ...timFilteredEventsByName];
    }

    let pageCount = Math.floor(timFilteredEvents.length / itemsPerPage);
    if (timFilteredEvents.length % itemsPerPage > 0) pageCount += 1;

    let lowerIndex = (currentPage - 1) * itemsPerPage;
    let upperIndex = lowerIndex + itemsPerPage;

    let displayedEvents = timFilteredEvents.slice(lowerIndex, upperIndex);

    const timData = {
        component: "TIM",
        title: "events",
        firstHeaders: ["Id", "Event Id", "Event Name"],
        data: displayedEvents
    };

    const handlePageClick = (e) => {
        setCurrentPage(e.selected + 1);
    }

    return (
        <main className="main-info">
            <LastUpdate />
            <TimEventSearch updateSearchValue={setSearchValue} searchValue={searchValue} />

            <div>
                <TimTable section={timData} />
            </div>
            <ReactPaginate
                className='react-paginate normal'
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />
        </main>
    )
}

export default TimData;
export async function loader({ request }) {
    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    //const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    const response = await fetch(`${baseURL}/api/tim/events`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    if (!response.ok) {
        // TODO: Handle the rerror
    }
    const responseData = await response.json();


    responseData.forEach(timEvent => {
        if (timEvent["eventName"] === null) {
            timEvent["eventName"] = "None"
        }
    })

    return responseData;
}