function TimEventsObjects(props) {
    return (
        <div className="tim-events-objects-container">
            <div className="tim-events-objects-header">
                <p><strong>Object Name: </strong>{props.object.name}</p>
                <p><strong>UUID: </strong>{props.object.uuid}</p>
                <p><strong>Meta Category: </strong>{props.object["meta-category"]}</p>
                <p><strong>Description: </strong>{props.object.description}</p>
                <p><strong>Template: </strong>{props.object.template_uuid}</p>
                <p><strong>References: </strong>{props.object.ObjectReference.length}</p>
            </div>
            <div className="tim-events-objects-body">
                {props.object.Attribute.map(attr => {
                    return (
                        <div className="tim-events-objects-attr" key={`${attr.value}-${attr.timestamp}`}>
                            <p>{Date(attr.timestamp * 1000)}</p>
                            <p>{attr.category}</p>
                            <div>
                                <p>{attr.object_relation}</p>
                                <p>{attr.type}</p>
                            </div>
                            <p>{attr.value}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TimEventsObjects;