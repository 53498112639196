import { useLoaderData } from 'react-router-dom';
import { useEffect } from 'react';

import LastUpdate from '../SharedComponents/LastUpdate';
import BackButton from '../SharedComponents/BackButton';

import '../MainData.css'

function HvaCampaigns() {
    const hvaCampaignData = useLoaderData();

    const refreshStatus = localStorage.getItem("refresh");
    useEffect(() => {
        if (refreshStatus === "on") {
            const interval = setInterval(() => {
                window.location.reload(false);
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [refreshStatus]);

    return (
        <div className='main-info'>
            <LastUpdate />
            <BackButton />

            <h2 className='subheading'>HVA Campaigns</h2>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        <th>Assess</th>
                    </tr>
                </thead>
                <tbody>

                    {hvaCampaignData.map((item) => {
                        return (
                            <tr key={`tr-${item.id}`}>
                                <th>{item.id}</th>
                                <td>{item.createdAt}</td>
                                <td>{item.updatedAt}</td>
                                <td>{item.name}</td>
                                <td>{item.start_date}</td>
                                <td>{item.end_date}</td>
                                <td>{item.status}</td>
                                <td className={item.assess ? "assess--true" : "assess--false"}>{item.assess ? "True" : "False"}</td>
                            </tr>)
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default HvaCampaigns

export async function loader({ request }) {
    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    //const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    // Get the HVA Campaigns
    const response = await fetch(`${baseURL}/api/hva/campaigns`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    if (!response.ok) {
        // TODO: Handle the rerror
    }
    const responseData = await response.json();

    // const responseData = [
    //     {
    //         "id": 1,
    //         "createdAt": "2021-10-25",
    //         "updatedAt": "2022-10-25",
    //         "name": "campaign1",
    //         "start_date": "2021-10-25",
    //         "end_date": "2022-10-25",
    //         "status": "created",
    //         "assess": true
    //     },
    //     {
    //         "id": 2,
    //         "createdAt": "2022-01-15",
    //         "updatedAt": "2023-01-15",
    //         "name": "campaign2",
    //         "start_date": "2022-01-15",
    //         "end_date": "2023-01-15",
    //         "status": "active",
    //         "assess": false
    //     }
    // ];

    return responseData;
};