import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function TviaForm() {
    const [formHeaderShow, setFormHeaderShow] = useState(false);
    const [networkValue, setNetworkValue] = useState("");
    const [inputExtraClasses, setInputExtraClasses] = useState("")

    const networkValueHandler = (event) => {
        setNetworkValue(event.target.value);
        setInputExtraClasses("");
    }
    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if (networkValue === "") {
            setInputExtraClasses("empty-input");
            NotificationManager.warning("Please provide a network value");
            return
        }

        // const currentUrl = window.location.href;
        // const testURL = currentUrl.split("/");
        // const protocol = testURL[0];
        // const domain = testURL[2].split(":")[0];
        //const baseURL = protocol + "//" + domain + ":8080";
        const baseURL = "https://secant.simavi.ro/api/dsbbackend"

        // const DIM_TOKEN = localStorage.getItem("token");

        const requestData = { network: networkValue };
        const response = await fetch(`${baseURL}/api/tvia/scan`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });
        if (!response.ok) {
            // Handle the rerror
        }
        NotificationManager.success(`Network scan started for network ${networkValue}`);
        setNetworkValue("");
    }

    if (formHeaderShow) {
        return (
            <div className='main-page-input-container'>
                <div className='form-header' onClick={() => setFormHeaderShow(formHeaderShow => !formHeaderShow)}>
                    <h2 className='heading-tertiary'>Start a new network scan</h2>
                    <span className="material-symbols-outlined"> {formHeaderShow ? "expand_more" : "expand_less"} </span>
                </div>
                <form className={`form-container`} onSubmit={onSubmitHandler}>
                    <div>
                        <label className='subheading' htmlFor='network'>Target Network</label>
                        <input className={inputExtraClasses} type='text' id='network' placeholder='0.0.0.0/0' value={networkValue} onChange={networkValueHandler}></input>
                    </div>
                    <button className='btn' type='submit'>Start Scanning</button>
                </form>
            </div>
        )
    } else {
        return (
            <div className='main-page-input-container'>
                <div className='form-header' onClick={() => setFormHeaderShow(formHeaderShow => !formHeaderShow)}>
                    <h2 className='heading-tertiary'>Start a new network scan</h2>
                    <span className="material-symbols-outlined"> {formHeaderShow ? "expand_more" : "expand_less"} </span>
                </div>
            </div>
        )
    }
}

export default TviaForm;