import { useState } from 'react';
import { Link } from 'react-router-dom';

import './HeaderNav.css'

function HeaderNav(props) {
    const [userMenuDisplay, setUserMenuDisplay] = useState(false);
    const openUserMenu = () => setUserMenuDisplay(true);
    const closeUserMenu = () => setUserMenuDisplay(false);
    const username = localStorage.getItem('username')

    return (
        <nav className="header-nav-container user-nav">
            <ul className="user-nav-list">
                <li className="user-role li-link">
                    <span className="material-symbols-outlined"> folder_open </span>
                    <span>Admin</span>
                </li>
                <li className="user-name-pic li-link" onMouseOver={openUserMenu} onMouseOut={closeUserMenu}>
                    <span>{username}</span>
                    <span className="user-pic material-symbols-outlined">
                        account_circle
                    </span>
                    <Link to="/logout" className={`user-list ${userMenuDisplay ? "" : "hidden"}`}>
                        <p>
                            <span className="material-symbols-outlined">logout</span>
                            <span>Logout</span>
                        </p>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default HeaderNav;