import { Link } from 'react-router-dom'

function UsefulPage(props) {
    return (
        <Link to={props.usefulPage.url}>
            <div className='home-page__useful center'>
                <div>
                    <h2 className='home-page__useful__name'>{props.usefulPage.name}</h2>
                    <p className='home-page__useful__desc'>{props.usefulPage.description}</p>
                </div>
            </div>
        </Link>
    )
}

export default UsefulPage;