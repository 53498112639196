import { useLoaderData } from 'react-router-dom';
import { useState } from "react";
import { useEffect } from 'react';
import { Link } from 'react-router-dom'

import EventReport from "../../../../EventReports/EventReport";
import LastUpdate from '../SharedComponents/LastUpdate';
import BackButton from '../SharedComponents/BackButton';

import '../MainData.css'

function HvaReports() {
    const hvaReportData = useLoaderData();
    const [hideStatus, setHideStatus] = useState({ status: true, data: null });

    const refreshStatus = localStorage.getItem("refresh");
    useEffect(() => {
        if (refreshStatus === "on") {
            const interval = setInterval(() => {
                window.location.reload(false);
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [refreshStatus]);

    return (
        <div className='main-info'>
            <LastUpdate />
            <BackButton />

            <h2 className='subheading'>HVA Reports</h2>
            <table>
                <thead>
                    <tr>
                        <th>Report ID</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Campaign ID</th>
                        <th>PDF</th>
                    </tr>
                </thead>
                <tbody>

                    {hvaReportData.map((item) => {
                        return (
                            <tr key={`tr-${item.id}`}>
                                <th>{item.id}</th>
                                <td>{item.createdAt}</td>
                                <td>{item.updatedAt}</td>
                                <td><Link to="/components/hva/campaigns">{item.campaignId}</Link></td>
                                <td><span className="material-symbols-outlined open-report-button" onClick={() => {
                                    setHideStatus({ status: false, data: item });
                                }}> visibility </span> </td>
                            </tr>)
                    })}
                </tbody>
            </table>
            <EventReport hideStatus={hideStatus} setHideStatus={setHideStatus} title="HVA Report" component="HVA" />
        </div>
    )
}

export default HvaReports

export async function loader({ request }) {
    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    //const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    // Get the HVA Reports
    const response = await fetch(`${baseURL}/api/hva/reports`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    if (!response.ok) {
        // TODO: Handle the rerror
    }
    const responseData = await response.json();
    // const responseData = [
    //     {
    //         "id": 1,
    //         "createdAt": "2021-10-25",
    //         "updatedAt": "2022-10-25",
    //         "campaignId": 1,
    //         "pdf": "<Report 1>"
    //     },
    //     {
    //         "id": 2,
    //         "createdAt": "2021-10-25",
    //         "updatedAt": "2022-10-25",
    //         "campaignId": 1,
    //         "pdf": "<Report 2>"
    //     }
    // ];

    return responseData;
};