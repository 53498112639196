import './EventReport.css'

function EventReport(props) {
    const checkEscape = (e) => {
        e.target.value = 'esc' && props.setHideStatus({ status: true, data: null });
    }

    if (!props.hideStatus.status) {
        return (
            <div className="report-info-box-bg center" onClick={() => props.setHideStatus({ status: true, data: null })}>
                <div className="report-info-box normal" tabIndex={0} onKeyDown={checkEscape} onClick={(e) => e.stopPropagation()}>
                    <div className="close-btn" onClick={() => props.setHideStatus({ status: true, data: null })}><span className="material-symbols-outlined">
                        close
                    </span></div>
                    <p className='subheading'>{`${props.component} > ${props.title}`}</p>
                    <div><pre>{JSON.stringify(props.hideStatus.data, null, 2)}</pre></div>
                </div>
            </div>
        )
    }
}

export default EventReport;