import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function HvaForm() {
    const [formHeaderShow, setFormHeaderShow] = useState(false);
    const [inputValues, setInputValues] = useState({ "campName": "", "startDate": "", "endDate": "", "assess": true, "dep": { "name": "" } });
    const [inputExtraClasses, setInputExtraClasses] = useState({ "campName": "", "startDate": "", "endDate": "", "dep": { "name": "" } })

    // Recipients Stuff
    const [recipientList, setRecipientList] = useState([])
    const addItem = () => {
        const newItem = { id: recipientList.length, value: { "first": "", "last": "", "email": "" } };
        setRecipientList([...recipientList, newItem]);
    };

    const deleteItem = (id) => {
        const updatedItems = recipientList.filter(item => item.id !== id);
        setRecipientList(updatedItems);
    };

    const updateRecipientsHandler = (event) => {
        let updatedItems = [...recipientList];
        const itemId = Number(event.target.getAttribute('item-id'))
        let index = recipientList.findIndex(x => x.id === itemId);
        updatedItems[index]["value"][event.target.getAttribute('item-field')] = event.target.value
        setRecipientList(updatedItems);
    }

    const inputValueHandler = (event) => {
        if (event.target.id === "campName") {
            setInputValues(currentValues => ({ ...currentValues, "campName": event.target.value }));
            setInputExtraClasses(currentValues => ({ ...currentValues, "campName": "" }));
        }
        if (event.target.id === "startDate") {
            setInputValues(currentValues => ({ ...currentValues, "startDate": event.target.value }))
            setInputExtraClasses(currentValues => ({ ...currentValues, "startDate": "" }));
        }
        if (event.target.id === "endDate") {
            setInputValues(currentValues => ({ ...currentValues, "endDate": event.target.value }))
            setInputExtraClasses(currentValues => ({ ...currentValues, "endDate": "" }));
        }
        if (event.target.id === "assessTrue") setInputValues(currentValues => ({ ...currentValues, "assess": true }))
        if (event.target.id === "assessFalse") setInputValues(currentValues => ({ ...currentValues, "assess": false }))
        if (event.target.id === "depName") {
            setInputValues(currentValues => ({ ...currentValues, "dep": { ...currentValues["dep"], "name": event.target.value } }))
            setInputExtraClasses(currentExtraClasses => ({ ...currentExtraClasses, "dep": { ...currentExtraClasses["dep"], "name": "" } }));
        }
    }
    const onSubmitHandler = async (event) => {
        event.preventDefault();
        let inputError = false;
        // Check empty values
        if (inputValues["campName"] === "") {
            setInputExtraClasses(currentExtraClasses => ({ ...currentExtraClasses, "campName": "empty-input" }));
            NotificationManager.warning("Please provide a name for the campaign");
            inputError = true;
        }
        if (inputValues["startDate"] === "") {
            setInputExtraClasses(currentExtraClasses => ({ ...currentExtraClasses, "startDate": "empty-input" }));
            NotificationManager.warning("Please provide a Starting Date for the campaign");
            inputError = true;
        }
        if (inputValues["endDate"] === "") {
            setInputExtraClasses(currentExtraClasses => ({ ...currentExtraClasses, "endDate": "empty-input" }));
            NotificationManager.warning("Please provide an Ending Date for the campaign");
            inputError = true;
        }
        if (inputValues["dep"]["name"] === "") {
            setInputExtraClasses(currentExtraClasses => ({ ...currentExtraClasses, "dep": { ...currentExtraClasses["dep"], "name": "empty-input" } }));
            NotificationManager.warning("Please provide a Department Name");
            inputError = true;
        }
        // Check endDate > startDate
        if (inputValues["startDate"] > inputValues["endDate"]) {
            setInputExtraClasses(currentExtraClasses => ({ ...currentExtraClasses, "endDate": "empty-input", "startDate": "empty-input" }));
            NotificationManager.warning("The Ending Date must be After the Starting Date of the Campaign");
            inputError = true;
        }

        // Check Recipients exist
        if (recipientList.length === 0) {
            NotificationManager.warning("Please add at least one recipient");
            inputError = true;
        }

        if (inputError) return

        let hvaData = {
            "campaign_name": inputValues["campName"],
            "campaign_start_date": inputValues["startDate"],
            "campaign_end_date": inputValues["endDate"],
            "assess": inputValues["assess"],
            "departments":
                [
                    {
                        "department_name": inputValues["dep"]["name"],
                        "recipients":
                            recipientList.map(rec => (
                                {
                                    "recipient_email_address": rec["value"]["email"],
                                    "first_name": rec["value"]["first"],
                                    "last_name": rec["value"]["last"]
                                }
                            ))
                    }
                ]
        }

        // const currentUrl = window.location.href;
        // const testURL = currentUrl.split("/");
        // const protocol = testURL[0];
        // const domain = testURL[2].split(":")[0];
        // const baseURL = protocol + "//" + domain + ":8080";
        const baseURL = "https://secant.simavi.ro/api/dsbbackend"

        const DIM_TOKEN = localStorage.getItem("token");

        const response = await fetch(`${baseURL}/api/hva/scan`, {
            method: "POST",
            headers: {
                "Authorization": `Basic ${DIM_TOKEN}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(hvaData)
        });
        if (!response.ok) {
            // Handle the rerror
        }
        NotificationManager.success(`New HVA Campaign Started ${inputValues["campName"]}`);
        setInputValues({ "campName": "", "startDate": "", "endDate": "", "assess": true, "dep": { "name": "" } });
        setInputExtraClasses({ "campName": "", "startDate": "", "endDate": "", "dep": { "name": "" } });
        setRecipientList([]);
        return
    }

    if (formHeaderShow) {
        return (
            <div className='main-page-input-container'>
                <div className='form-header' onClick={() => setFormHeaderShow(formHeaderShow => !formHeaderShow)}>
                    <h2 className='heading-tertiary'>Start a new HVA Campaign</h2>
                    <span className="material-symbols-outlined"> {formHeaderShow ? "expand_more" : "expand_less"} </span>
                </div>
                <form className={`form-container`} onSubmit={onSubmitHandler}>
                    <div>
                        <label className='subheading' htmlFor='campName'>Campaign Name</label>
                        <input className={inputExtraClasses["campName"]} type='text' id='campName' placeholder='test-campaign' value={inputValues["campName"]} onChange={inputValueHandler}></input>
                    </div>
                    <div>
                        <label className='subheading' htmlFor='startDate'>Start Date</label>
                        <input className={inputExtraClasses["startDate"]} type='date' id='startDate' value={inputValues["startDate"]} onChange={inputValueHandler}></input>
                    </div>
                    <div>
                        <label className='subheading' htmlFor='endDate'>End Date</label>
                        <input className={inputExtraClasses["endDate"]} type='date' id='endDate' value={inputValues["endDate"]} onChange={inputValueHandler}></input>
                    </div>
                    <div>
                        <label className='subheading'>Assess</label>
                        <div className='choices'>
                            <div className='choice'>
                                <label htmlFor='assessTrue'>True</label>
                                <input className={`radio-button {inputExtraClasses["endDate"]}`} type='radio' name='assess' id='assessTrue' value={true} checked={inputValues["assess"] === true} onChange={inputValueHandler}></input>
                            </div>
                            <div className='choice'>
                                <label htmlFor='assessFalse'>False</label>
                                <input className={`radio-button {inputExtraClasses["endDate"]}`} type='radio' name='assess' id='assessFalse' value={false} checked={inputValues["assess"] === false} onChange={inputValueHandler}></input>
                            </div>
                        </div>
                    </div>
                    <div className='departments'>
                        <h4 className='subheading'>Departments</h4>
                        <div>
                            <input className={inputExtraClasses["dep"]["name"]} type='text' id='depName' placeholder='Department Name' value={inputValues["dep"]["name"]} onChange={inputValueHandler}></input>
                        </div>
                        <div className='recipients-container'>
                            <label className='subheading'>Recipients</label>
                            {recipientList.map(item => (
                                <div key={item.id} className='recipient-form'>
                                    <div key={item.id} className='recipient-form__first'>
                                        <input required key={item.id} type='text' id={`recipient-${item.id}-first`} item-id={item.id} item-field="first" placeholder='First Name' value={recipientList.filter(obj => {
                                            return obj.id === Number(item.id)
                                        })[0]["value"]["first"]} onChange={updateRecipientsHandler}></input>
                                    </div>
                                    <div className='recipient-form__last'>
                                        <input required key={item.id} type='text' id={`recipient-${item.id}-last`} item-id={item.id} item-field="last" placeholder='Last Name' value={recipientList.filter(obj => {
                                            return obj.id === Number(item.id)
                                        })[0]["value"]["last"]} onChange={updateRecipientsHandler}></input>
                                    </div>
                                    <div className='recipient-form__email'>
                                        <input required key={item.id} type='email' id={`recipient-${item.id}-email`} item-id={item.id} item-field="email" placeholder='Email' value={recipientList.filter(obj => {
                                            return obj.id === Number(item.id)
                                        })[0]["value"]["email"]} onChange={updateRecipientsHandler}></input>
                                    </div>
                                    <button className='btn' onClick={() => deleteItem(item.id)}><span className="material-symbols-outlined">
                                        delete
                                    </span></button>
                                </div>
                            ))}
                            <button type='button' className='btn' onClick={() => addItem()}><span className="material-symbols-outlined">
                                person_add
                            </span></button>
                        </div>
                    </div>
                    <button className='btn' type='submit'>Start Campaign</button>
                </form>
            </div>
        )
    } else {
        return (
            <div className='main-page-input-container'>
                <div className='form-header' onClick={() => setFormHeaderShow(formHeaderShow => !formHeaderShow)}>
                    <h2 className='heading-tertiary'>Start a new HVA Campaign</h2>
                    <span className="material-symbols-outlined"> {formHeaderShow ? "expand_more" : "expand_less"} </span>
                </div>
            </div>
        )
    }
}

export default HvaForm;