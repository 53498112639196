import { useLoaderData } from 'react-router-dom';

import LastUpdate from '../SharedComponents/LastUpdate';
import BackButton from '../SharedComponents/BackButton';

function TviaAssetService() {
    const tviaAssetData = useLoaderData();
    return (
        <div>
            <LastUpdate />
            <BackButton />

            <div className='tvia-asset-details-container grid--2-cols'>
                <div className='main-info'>
                    <h2 className='subheading'>Services</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Service ID</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                                <th>CPE</th>
                                <th>Name</th>
                                <th>Version</th>
                                <th>Port Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tviaAssetData.map(item => {
                                return (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.createdAt}</td>
                                        <td>{item.updatedAt}</td>
                                        <td>{item.cpe}</td>
                                        <td>{item.name}</td>
                                        <td>{item.version}</td>
                                        <td>{item.version}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TviaAssetService;

export async function loader({ request, params }) {
    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    //const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    const response = await fetch(`${baseURL}/api/tvia/services/${params.assetId}`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    const tviaAssetData = await response.json();
    return tviaAssetData;
}