import { Link } from "react-router-dom";

function TimTable(props) {

    return (

        <div>
            <h2 className='subheading'>{props.section.title}</h2>
            <table>
                <thead>
                    <tr>
                        {props.section.firstHeaders.map((header) => <th key={`th-${header}`}>{header}</th>)}
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    {props.section.data.map((item) => {
                        return (
                            <tr key={`tr-${item.id}`}>
                                <th>{item.id}</th>
                                <td>{item.eventId}</td>
                                <td>{item.eventName}</td>
                                <td><Link to={`/components/tim/${item.eventId}`}><span className="material-symbols-outlined open-report-button"> visibility </span></Link></td>
                            </tr>)
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default TimTable;