import secantBg from '../../img/secant-bg.png'


import './ComingSoon.css'
import '../MainPage/MainData/SecantComponents/MainData.css'

function ComingSoon() {
    return (
        <main className='main-info'>
            <div className='coming-soon-container'>
                <div className='coming-soon-container__headings'>
                    <h1 className='heading-secondary'>SECANT Dashboard</h1>
                    <p className='subheading'>For security professionals</p>
                    <p className='normal alert-coming-soon'><span className="material-symbols-outlined alert-icon">
                        warning
                    </span>This page will be available soon</p>
                </div>
                <img src={secantBg} alt='SECANT Background' className='secant-bg' />
            </div>
        </main>
    )
}

export default ComingSoon;