import { useSearchParams } from "react-router-dom";

import BackButton from "../SecantComponents/SharedComponents/BackButton";

import './DocumentationItem.css';

function DocumentationItem() {
    const documentationData = {
        CoCRAE: `<p><strong>CO-CRAE</strong> stands for <strong>C</strong>onnected <strong>O</strong>rganization <strong>C</strong>yber <strong>R</strong>isk <strong>A</strong>ssessment <strong>E</strong>ngine. It provides the risk assessment methodology for SECANT both in a proactive and a reactive manner (i.e., react when a security incident occurs). The results are consulted by the security administrators which prioritize and organize the optimal mitigation actions to reduce the risk of the entire asset topology.</p>
            <br />
            <br />
            <p>Within the Dashboard, a security administrator may visit the CO-CRAE tab to inspect the latest risk assessment results for the asset topology where SECANT is deployed. Specifically, the security administrator views for each asset (i.e., hardware or software asset):</p>
            <br />
            <ul>
            <li>👉 <strong>Individual risk level:</strong>  As computed for a particular asset during the latest risk assessment task.</li>
            <li>👉 <strong>Number of incoming attack path:</strong>  How many attack paths consider the particular asset as a target. It is computed during the latest risk assessment task.</li>
            <li>👉 <strong>Security Incident:</strong> An indication that an alarm has been raised by the SECANT monitoring agents for this particular asset.</li>
            </ul>
            <br />
            <p>Finally, the security administrator has the ability to launch a new risk assessment task (i.e., through a button in the CO-CRAE tab). This allows him/her to re-calculate the aforementioned values for each asset in order to receive an updated view of the entire topology.</p>`,
        TVIA: `
            <p>Τhe <strong>Technical Vulnerability and Impact Assessment</strong> component of SECANT is responsible for assessing the infrastructure of concern in terms of possible existing vulnerabilities in the assets that comprise the said infrastructure as well as monitoring the network and system logs with the aim to identify possible malicious actions.</p>
            <br />
            <br />
            <p>The TVIA is comprised by three components: The IDS that is responsible for monitoring the network of the infrastructure, the Monitoring Agent which monitors system events, and finally the Vulnerability Scanner that discovers assets, services and possible vulnerabilities in the ICT infrastructure.</p>`,
        HVA: `
            <p><strong>Human Vulnerability Assessment</strong> component aims to assess the susceptibility of the personnel, of an ICT infrastructure, in both phishing and in certain persuasion principles. The former is achieved through a Simulated Phishing Campaign by sending multiple phishing emails to the assessment participants, while the latter is being achieved through the use of questionnaires that assess the personality of the individual. In both cases, a report is generated which categorises the results per department.</p>
            <br />
            <br />
            <p>The tool automatically retrieves the users from the DIM component of SECANT and performs both the phishing and persuasion principle susceptibility assessment. Both during the runtime and after the campaign has ended the report of HVA can be retrieved to inform the system administrator of the overall susceptibility status of the infrastructure of concern.</p>`,
    }

    const [searchParams] = useSearchParams();
    const selectedComponent = searchParams.get("tool");

    return (
        <div className="documentation-item">
            <BackButton />
            <h1 className="heading-primary">{selectedComponent}</h1>
            <div className="normal" dangerouslySetInnerHTML={{ __html: documentationData[selectedComponent] }} />
            {/* {documentationData[selectedComponent].map(item => item.length > 0 ? <p className="normal">{item}</p> : <br />)} */}
        </div>
    )
}

export default DocumentationItem;