import { useNavigate } from 'react-router-dom';

import './BackButton.css';

function BackButton() {
    const navigate = useNavigate();
    return (
        <button className='back-button' onClick={() => navigate(-1)}>
            <span className="material-symbols-outlined">
                arrow_back
            </span>
            <span>Back</span>
        </button>
    )
}

export default BackButton;