import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

function TimRelatedEvents(props) {
    const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    let pageCount = Math.floor(props.relatedEvents.length / itemsPerPage);
    if (props.relatedEvents.length % itemsPerPage > 0) pageCount += 1;

    let lowerIndex = (currentPage - 1) * itemsPerPage;
    let upperIndex = lowerIndex + itemsPerPage;

    let displayedEvents = props.relatedEvents.slice(lowerIndex, upperIndex);

    const handlePageClick = (e) => {
        setCurrentPage(e.selected + 1);
    }

    return (
        <div>

            <ul className="tim-related-events-container">
                {displayedEvents.map(event => {
                    return (
                        <Link to={`/components/tim/${event.Event.id}`}>
                            <li className="tim-related-events-container-event" key={event.Event.id}>
                                <span>{event.Event.info}</span>
                                <span>{event.Event.date}</span>
                                <span>{event.Event.Orgc.name}</span>
                            </li>
                        </Link>
                    )
                })}
            </ul>
            <ReactPaginate
                className='react-paginate normal'
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
            />
        </div>
    )
}

export default TimRelatedEvents;