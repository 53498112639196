import { Form, redirect, } from "react-router-dom"
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import BackButton from "../SecantComponents/SharedComponents/BackButton";

import './Configuration.css'

function Configuration() {
    const refreshStatus = localStorage.getItem("refresh");

    return (
        <div className="main-info">
            <BackButton />

            <h1 className="heading-primary">Configuration</h1>
            <div className="config-container">
                <h2 className="subheading">Components</h2>
                <Form method="POST">
                    <div className="option-container">
                        <div className="badge">Auto Refresh Components Page</div>
                        <label className="toggler-wrapper style-1">
                            <input type="checkbox" name="refresh" defaultChecked={refreshStatus === "on" ? true : false} />
                            <div className="toggler-slider">
                                <div className="toggler-knob"></div>
                            </div>
                        </label>
                    </div>
                    <button className="btn">Save</button>
                </Form>
            </div>
        </div>
    );
}

export default Configuration;

export async function action({ request }) {
    const data = await request.formData();

    const refreshStatus = data.get("refresh");
    localStorage.setItem('refresh', refreshStatus);
    NotificationManager.success("Configuration updated!");
    return redirect("/home");
}