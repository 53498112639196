import { useLoaderData } from 'react-router-dom';
import LastUpdate from '../SharedComponents/LastUpdate';
import EventReport from "../../../../EventReports/EventReport";

import { useEffect, useState } from 'react';

import '../MainData.css'
import './Bae.css'

function Baedata() {
    const baeFetchedData = useLoaderData();
    const [hideStatus, setHideStatus] = useState({ status: true, data: null });


    const refreshStatus = localStorage.getItem("refresh");
    useEffect(() => {
        if (refreshStatus === "on") {
            const interval = setInterval(() => {
                window.location.reload(false);
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [refreshStatus]);

    return (
        <main className="main-info">
            <LastUpdate />

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>IP</th>
                        <th>Risk Score</th>
                        <th>Threat ID</th>
                        <th>Timestamp</th>
                        <th>Feature Weights</th>
                    </tr>
                </thead>

                <tbody>
                    {baeFetchedData.map(entry => {
                        return (
                            <tr key={entry.id}>
                                <td>{entry.id}</td>
                                <td>{entry.createdAt}</td>
                                <td>{entry.updatedAt}</td>
                                <td>{entry.ip}</td>
                                <td>{entry.riskScore}</td>
                                <td>{entry.threatId}</td>
                                <td>{entry.timestamp}</td>
                                <td><span className="material-symbols-outlined open-report-button" onClick={() => {
                                    setHideStatus({ status: false, data: entry.featureWeights });
                                }}> visibility </span> </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <EventReport hideStatus={hideStatus} setHideStatus={setHideStatus} title="Feature Weights" component="BAE" />
        </main>
    )
}

export default Baedata


export async function loader({ request }) {

    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    //const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    const Response = await fetch(`${baseURL}/api/bae/riskDevices`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    if (!Response.ok) {
        // TODO: Handle the rerror
    }
    const responseData = await Response.json();

    return responseData;
    // return [{
    //     "id": 1,
    //     "createdAt": "2024-05-29 15:05:10.564",
    //     "updatedAt": "2024-05-29 17:42:14.666",
    //     "ip": "0.0.0.1",
    //     "riskScore": 28,
    //     "threatId": "abc1234",
    //     "timestamp": "2023-09-27 12:25:00",
    //     "featureWeights": {
    //         "a": "avalue",
    //         "b": 12.2,
    //         "c": 12.2,
    //         "d": 12.2,
    //     }
    // }];
}