import { useLoaderData } from 'react-router-dom';
import LastUpdate from '../SharedComponents/LastUpdate';
import { NotificationManager } from 'react-notifications';
import { useEffect } from 'react';

import '../MainData.css'
import './CocraeData.css'

function CocraeData() {
    const cocraeFetchedData = useLoaderData();
    const cocraeAssets = cocraeFetchedData.content;

    const startRiskAssessment = async () => {
        // const currentUrl = window.location.href;
        // const testURL = currentUrl.split("/");
        // const protocol = testURL[0];
        // const domain = testURL[2].split(":")[0];
        //const baseURL = protocol + "//" + domain + ":8080";
        const baseURL = "https://secant.simavi.ro/api/dsbbackend"

        const DIM_TOKEN = localStorage.getItem("token");

        const response = await fetch(`${baseURL}/api/cocrae/scan`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `${DIM_TOKEN}`
            }
        });

        if (!response.ok) {
            // Handle the error
        }
        NotificationManager.success(`Risk Assessment process started`);
    }

    // Order based on Risk Level
    const riskLevelMapping = {
        "VH": 1,
        "H": 2,
        "M": 3,
        "L": 4,
        "VL": 5,
        "N/A": 6
    }
    cocraeAssets.forEach(element => {
        element["riskLevelNumber"] = riskLevelMapping[element.riskLevel] || 10;
    });
    cocraeAssets.sort(dynamicSort("riskLevelNumber"));

    const refreshStatus = localStorage.getItem("refresh");
    useEffect(() => {
        if (refreshStatus === "on") {
            const interval = setInterval(() => {
                window.location.reload(false);
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [refreshStatus]);

    return (
        <main className="main-info">
            <LastUpdate />
            <button className='btn btn--cocrae' onClick={startRiskAssessment}>Initiate Risk Assessment</button>

            <table>
                <thead>
                    <tr>
                        <th>Asset Name</th>
                        <th>Individual Risk Level</th>
                        <th>Number of Incoming Attack Paths</th>
                        <th>Ongoing Alerts</th>
                    </tr>
                </thead>

                <tbody>
                    {cocraeAssets.map(asset => {
                        return (
                            <tr key={asset.id}>
                                <td>{asset.name}</td>
                                <td>{asset.riskLevel}</td>
                                <td>{asset.noOfIncomingAttackPaths}</td>
                                <td className={asset.alarms.length ? "alarm alarm--on" : "alarm alarm--off"}>
                                    <span className="material-symbols-outlined">
                                        {asset.alarms.length ? "warning" : "task_alt"}
                                    </span>
                                    {asset.alarms.length ? "An incident is currently taking place" : "No reported incidents"}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </main>
    )
}

export default CocraeData;
export async function loader({ request }) {

    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    //const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    const Response = await fetch(`${baseURL}/api/cocrae/data`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    if (!Response.ok) {
        // TODO: Handle the rerror
    }
    const responseData = await Response.json();

    return responseData;
}

function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}
