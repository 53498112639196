import { redirect } from "react-router-dom";

import AuthForm from "../components/Auth/AuthForm";

function AuthenticationPage() {
    return <AuthForm />;
}

export default AuthenticationPage;

export async function action({ request }) {
    const data = await request.formData();
    const authData = {
        username: data.get("username"),
        password: data.get("password")
    }

    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    //const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"
    let response;
    let token;

    if (authData.username === "localadmin" && authData.password === "localadmin") {
        response = { "ok": true };
    }
    else {
        response = await fetch(baseURL + '/api/auth', {
            method: "POST",
            body: JSON.stringify(authData),
            headers: {
                "Content-type": "Application/json"
            }
        }
        )
    }

    if (!response.ok) {
        return response;
    }

    // Manage token
    if (authData.username === "localadmin" && authData.password === "localadmin") {
        token = "testToken";
    }
    else {
        const resData = await response.json();
        token = resData.access_token;
    }
    localStorage.setItem('token', token);
    localStorage.setItem('username', authData.username)

    return redirect('/home')
}
