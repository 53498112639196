import { Link } from "react-router-dom";

function LastUpdate() {
    return (
        <div className='last-update normal'>
            <span>Last update:  </span>{new Date().toLocaleString()} <Link to=""><span className="refresh-btn material-symbols-outlined">
                refresh
            </span></Link>
        </div>
    )
}

export default LastUpdate;