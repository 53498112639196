import { Link } from 'react-router-dom'

import TviaForm from './TviaForm';

import '../MainData.css'
import './Tvia.css'
import reports from '../../../../../img/reports.jpg'
import datacenter from '../../../../../img/datacenter.jpg'

function TviaData() {

    return (
        <main className="main-info">
            <TviaForm />

            <div className='grid grid--2-cols'>
                {/* <TviaTable key={section.title} section={section} /> */}
                <div>
                    <h2 className='subheading'>Assets</h2>
                    <div className='img-container'>
                        <Link to="/components/tvia/assets">
                            <img src={datacenter} alt='TVIA Assets' className='tvia-sub' />
                        </Link>
                    </div>
                </div>
                <div>
                    <h2 className='subheading'>Reports</h2>
                    <div className='img-container'>
                        <Link to="/components/tvia/reports">
                            <img src={reports} alt='TVIA Reports' className='tvia-sub' />
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default TviaData;

