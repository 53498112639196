import { useState } from 'react';

function TviaReportsSearch(props) {
    const [formHeaderShow, setFormHeaderShow] = useState(false);
    const newSort = (event) => {
        props.updateSortValue(event.target.value);
    }
    const newOrder = (event) => {
        props.updateSortOrder(event.target.value);
    }


    if (formHeaderShow) {
        return (
            <div className='main-page-input-container'>
                <div className='form-header' onClick={() => setFormHeaderShow(formHeaderShow => !formHeaderShow)}>
                    <h2 className='heading-tertiary'>Order Reports</h2>
                    <span className="material-symbols-outlined"> {formHeaderShow ? "expand_more" : "expand_less"} </span>
                </div>
                <form className={`form-container`}>
                    <div>
                        <label className='subheading' htmlFor='sortBy'>Sort By</label>
                        <select id="sortBy" onChange={newSort}>
                            <option value="id">Report ID</option>
                            <option value="createdAt">Created At</option>
                            <option value="updatedAt">Updated At</option>
                        </select>
                    </div>
                    <div>
                        <select id="sortBy" onChange={newOrder}>
                            <option value="">Ascending</option>
                            <option value="-">Descending</option>
                        </select>
                    </div>
                </form>
            </div>
        )
    } else {
        return (
            <div className='main-page-input-container'>
                <div className='form-header' onClick={() => setFormHeaderShow(formHeaderShow => !formHeaderShow)}>
                    <h2 className='heading-tertiary'>Order Reports</h2>
                    <span className="material-symbols-outlined"> {formHeaderShow ? "expand_more" : "expand_less"} </span>
                </div>
            </div>
        )
    }
}

export default TviaReportsSearch;