import { Link } from 'react-router-dom'

import '../MainData.css'
import './Hva.css'
import hva_reports from '../../../../../img/hva_reports.webp'
import hva_deps from '../../../../../img/hva_deps.webp'
import hva_campaigns from '../../../../../img/hva_campaigns.webp'
import HvaForm from './HvaForm'

function HvaData() {

    return (
        <main className="main-info">
            <HvaForm />

            <div className='grid grid--3-cols'>
                <div>
                    <h2 className='subheading'>Campaigns</h2>
                    <div className='img-container'>
                        <Link to="/components/hva/campaigns">
                            <img src={hva_campaigns} alt='HVA Campaigns' className='hva-sub' />
                        </Link>
                    </div>
                </div>
                <div>
                    <h2 className='subheading'>Departments</h2>
                    <div className='img-container'>
                        <Link to="/components/hva/departments">
                            <img src={hva_deps} alt='HVA Departments' className='hva-sub' />
                        </Link>
                    </div>
                </div>
                <div>
                    <h2 className='subheading'>Reports</h2>
                    <div className='img-container'>
                        <Link to="/components/hva/reports">
                            <img src={hva_reports} alt='HVA Reports' className='hva-sub' />
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default HvaData;

