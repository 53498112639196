import { useState } from "react";
import VocabularySearch from "./VocabularySearch";

import BackButton from "../SecantComponents/SharedComponents/BackButton";

function Vocabulary() {
    const vocabularyData = {
        "Alarm": "An alarm raised by the SECANT monitoring agents concerning a security incident taking place in the Asset topology where SECANT framework is deployed.",
        "Attack path": "A set of vulnerable Assets that an attacker can leverage to launch a complex attack starting from an entry Asset and targeting a different Asset within the asset topology. For each Attack path, a risk value is also associated based on the participating Assets.",
        "Attack path calculation": "The task that calculates the Attack paths that are associated with a Business service.",
        "Attack scenario": "The association of a Vulnerability, a Threat, an Asset, and a Business service. This information is critical for the calculation of the Individual risk level.",
        "Asset": "An asset is any entity participating in a Business service. An Asset can be tangible or intangible. An Asset can be a hardware device, an operating system, a service, or even a piece of information or a group of persons.",
        "Asset dependency": "The expression of a connection between two assets. There are various types of dependencies to capture the different links between the various Assets.",
        "Asset graph": "The visualization of an asset topology in a graph where the nodes are the Assets and the edges are the Asset dependencies defined in an asset topology.",
        "Asset topology": "An asset topology consists of a set of Assets and their Asset dependencies. An Asset topology is associated with a specific Business service.",
        "Business service": "A logical entity where an asset topology is defined. All functions within CO-CRAE are executed over a Business service. An Asset may participate in more than one Business service.",
        "CPE": "CPE is a structured naming scheme for information technology systems, software, and packages.",
        "CVE": "Common Vulnerabilities and Exposures – Each known system vulnerability corresponds to a specific CVE. This CVE contains metrics that describe specific aspects of the enumerated Vulnerability. For example the metric 'Exploitability' refers to the degree of difficulty for possible exploitation by malicious actors.",
        "CVSS": "CVSS, or the Common Vulnerability Scoring System, is a standard used to assess and communicate the severity of security vulnerabilities. It assigns a numerical score to vulnerabilities based on their characteristics, including exploitability, impact, and severity. The score helps security professionals prioritize and address vulnerabilities effectively.",
        "DIM": "Decentralized Identity Manager",
        "HVA": "The Human Vulnerability Assessment component",
        "IP": "An IP (Internet Protocol) address is a uniquely-identifying string of numbers assigned to each Internet-connected device or any device connected to a network.",
        "Individual risk level": "The result of the CO-CRAE risk assessment methodology for an Asset. The Individual risk level is calculated based on the Attack scenario entries which are associated with a specific Asset.",
        "MAC": "A MAC (Media Access Control) address, sometimes referred to as a hardware or physical address, is a unique, 12-character alphanumeric attribute that is used to identify individual electronic devices on a network. An example of a MAC address is: 00-B0-D0-63-C2-26.",
        "Phishing": "The process of sending messages or emails with malicious links or attachments with the goal of gaining access to confidential information.",
        "Persuasion Principles": "Certain techniques of persuasion commonly used by malicious individuals such as Commitment, Authority, Liking, etc.",
        "Risk assessment task": "The task that calculates the Individual risk level and the number of incoming Attack paths for each Asset within a Business service.",
        "Risk graph": "A way of visualizing the Individual risk level of each Asset within an Asset topology. It is an Asset graph where for each node the Individual risk level is also provided.",
        "Service": "A software service, often referred to as a 'service' or 'web service', is a program or application that provides specific functionalities over a network, typically the internet. These services can perform a wide range of tasks, such as processing data, hosting websites, handling email, or providing access to databases.",
        "TVIA": "The Technical Vulnerability and Impact Assessment component",
        "Threat": "A type of attack that can take place within the asset topology. Within CO-CRAE, for each Threat there is one configurable parameter that specifies the likelihood for a Threat to occur in the Asset topology.",
        "Vulnerability": "Weakness that could be exploited or triggered by a threat source. Within CO-CRAE, for each Vulnerability there are some attributes that are used to identify its impact."
    }

    const [searchValue, setSearchValue] = useState("")

    let vocFilteredData = vocabularyData;

    if (searchValue !== "") {
        // vocFilteredData = vocabularyData.filter(term => term.startsWith(searchValue));
        vocFilteredData = Object.fromEntries(Object.entries(vocabularyData).filter(([key]) => key.startsWith(searchValue)));
    }

    return (
        <div className="main-info">
            <BackButton />
            <VocabularySearch updateSearchValue={setSearchValue} searchValue={searchValue} />
            <table>
                <thead>
                    <tr>
                        <th>Term</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(vocFilteredData).map(key => {
                        return (
                            <tr key={key}>
                                <th>{key}</th>
                                <td>{vocabularyData[key]}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )

}

export default Vocabulary;