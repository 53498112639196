function SecantComponentsMenu(props) {
    if (props.menuOpen) {
        return (
            <div className="tools-nav-list-item__label" onClick={props.navItem["onClick"]}>
                <span className="material-symbols-outlined"> {props.navItem["logo"]} </span>
                <span>{props.navItem["name"]}</span>
                {props.navItem["extraLogo"].map(extraLogo => <span key="extraLogo" className="material-symbols-outlined">
                    {extraLogo}
                </span>)}
            </div>
        )
    } else {
        return (
            <div className="tools-nav-list-item__label" onClick={props.navItem["onClick"]}>
                <span className="material-symbols-outlined"> {props.navItem["logo"]} </span>
                {props.navItem["extraLogo"].map(extraLogo => <span key="extraLogo" className="material-symbols-outlined">
                    {extraLogo}
                </span>)}
            </div>
        )
    }
}

export default SecantComponentsMenu;