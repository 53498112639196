import { useLoaderData } from 'react-router-dom';

import LastUpdate from '../SharedComponents/LastUpdate';
import BackButton from '../SharedComponents/BackButton';

function TviaAssetVul() {
    const tviaAssetData = useLoaderData();
    return (
        <div>
            <LastUpdate />
            <BackButton />

            <div className='main-info'>
                <h2 className='subheading'>Vulnerabilities</h2>
                <table>
                    <thead>
                        <tr>
                            <th>CVE ID</th>
                            <th>Severity</th>
                            <th>Exploitability</th>
                            <th>Base Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tviaAssetData.map(item => {
                            return (
                                <tr key={item.cve_id}>
                                    <td>{item.cve_id}</td>
                                    <td>{item.severity}</td>
                                    <td>{item.exploitability}</td>
                                    <td>{item.base_score}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TviaAssetVul;

export async function loader({ request, params }) {
    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    //const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    const response = await fetch(`${baseURL}/api/tvia/vulnerabilities/${params.assetId}`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    const tviaAssetData = await response.json();

    return tviaAssetData;
}