import TimRelatedEvents from "./TimRelatedEvents";
import TimEventsObjects from "./TimEventsObjects";

import { useLoaderData } from "react-router-dom";

function TimEvents() {
    const timEventsData = useLoaderData();

    return (
        <div className="normal tim-events-main">
            <h1 className="heading-primary">{timEventsData.Event.info}</h1>
            <div className="tim-events-container">
                <div className="tim-events-container__info">
                    <h2 className="subheading">Event Details</h2>
                    <table>
                        <tbody>
                            <tr>
                                <th>Event ID</th>
                                <td>{timEventsData.Event.id}</td>
                            </tr>
                            <tr>
                                <th>UUID</th>
                                <td>{timEventsData.Event.uuid}</td>
                            </tr>
                            <tr>
                                <th>Creator Org</th>
                                <td>{timEventsData.Event.Orgc.name}</td>
                            </tr>
                            <tr>
                                <th>Protected Event</th>
                                <td>{timEventsData.Event.protected ? "Event in protected mode" : "Event in unprotected mode"}</td>
                            </tr>
                            <tr>
                                <th>Tags</th>
                                <td>{timEventsData.Event.Tag.map(tag => <div key={tag.name} style={{ backgroundColor: tag.colour, padding: "0.3rem 1rem" }}>{tag.name}</div>)}</td>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <td>{timEventsData.Event.date}</td>
                            </tr>
                            <tr>
                                <th>Threat level</th>
                                <td>{timEventsData.Event.threat_level_id}</td>
                            </tr>
                            <tr>
                                <th>Analysis</th>
                                <td>{timEventsData.Event.analysis}</td>
                            </tr>
                            <tr>
                                <th>Distribution</th>
                                <td>{timEventsData.Event.distribution}</td>
                            </tr>
                            <tr>
                                <th>Published</th>
                                <td>{!timEventsData.Event.published ? <span style={{ backgroundColor: "#ff8080", padding: "0 1rem", color: "white" }}>NO</span> : <span style={{ backgroundColor: "#8cd98c", padding: "0 1rem", color: "white" }}>YES</span>}</td>
                            </tr>
                            <tr>
                                <th>#Attributes</th>
                                <td>{timEventsData.Event.attribute_count}</td>
                            </tr>
                            <tr>
                                <th>Correlation</th>
                                <td>{timEventsData.Event.disable_correlation ? <span style={{ backgroundColor: "#ff8080", padding: "0 1rem", color: "white" }}>Disabled</span> : <span style={{ backgroundColor: "#8cd98c", padding: "0 1rem", color: "white" }}>Enabled</span>}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="tim-events-container__events">
                    <h2 className="subheading">Related Events</h2>
                    <TimRelatedEvents relatedEvents={timEventsData.Event.RelatedEvent} />
                </div>
                <div className="tim-events-container__objects">
                    <h2 className="subheading">Objects</h2>
                    {timEventsData.Event.Object.map(object => <TimEventsObjects object={object} key={object.uuid} />)}
                </div>
            </div>
        </div>
    )
}

export default TimEvents;

export async function loader({ request, params }) {
    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    //const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    const response = await fetch(`${baseURL}/api/tim/events/${params.eventId}`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    const timEventsData = await response.json()

    return timEventsData;
}
