import { Link } from 'react-router-dom'

function HomePageComponent(props) {
    return (
        <Link to={props.homeComponent.url}>
            <div className='home-page__component-card'>
                <div className='home-page__component-card__titles'>
                    <h2 className='home-page__component-card__abbr'>{props.homeComponent.name}</h2>
                    <p className='home-page__component-card__name'>{props.homeComponent.fullName}</p>
                </div>
                <p className='home-page__component-card__desc center'>{props.homeComponent.description}</p>
            </div>
        </Link>
    )
}

export default HomePageComponent;