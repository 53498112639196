import { useState } from 'react';

function VocabularySearch(props) {
    const [formHeaderShow, setFormHeaderShow] = useState(false);
    const newSearch = (event) => {
        props.updateSearchValue(event.target.value);
    }

    if (formHeaderShow) {
        return (
            <div className='main-page-input-container'>
                <div className='form-header' onClick={() => setFormHeaderShow(formHeaderShow => !formHeaderShow)}>
                    <h2 className='heading-tertiary'>Search</h2>
                    <span className="material-symbols-outlined"> {formHeaderShow ? "expand_more" : "expand_less"} </span>
                </div>
                <form className={`form-container`} onSubmit={(event) => event.preventDefault()}>
                    <div>
                        <input type='text' id='eventToSearch' placeholder='Term' value={props.searchValue} onChange={newSearch}></input>
                    </div>
                    <button className='btn' type='submit' onClick={() => props.updateSearchValue("")}>Clear</button>
                </form>
            </div>
        )
    } else {
        return (
            <div className='main-page-input-container'>
                <div className='form-header' onClick={() => setFormHeaderShow(formHeaderShow => !formHeaderShow)}>
                    <h2 className='heading-tertiary'>Search</h2>
                    <span className="material-symbols-outlined"> {formHeaderShow ? "expand_more" : "expand_less"} </span>
                </div>
            </div>
        )
    }
}

export default VocabularySearch;