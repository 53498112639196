import { useLoaderData } from 'react-router-dom';
import LastUpdate from '../SharedComponents/LastUpdate';
import ApicdsSearch from './ApicdsSearch';
import { useEffect } from 'react';
import { useState } from "react";

import { v4 as uuidv4 } from 'uuid';

import '../MainData.css'
import './Apicds.css'

function Apicdsdata() {
    const apicdsFetchedData = useLoaderData();
    const [searchValue, setSearchValue] = useState("");
    const refreshStatus = localStorage.getItem("refresh");

    useEffect(() => {
        if (refreshStatus === "on") {
            const interval = setInterval(() => {
                window.location.reload(false);
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [refreshStatus]);

    // Filter Data
    let apicdsFilteredData = apicdsFetchedData;

    if (searchValue !== "") {
        console.log(searchValue);
        apicdsFilteredData = apicdsFetchedData.filter(event =>
        (event.senderIp.startsWith(searchValue) ||
            event.mac.startsWith(searchValue) ||
            event.osName.startsWith(searchValue) ||
            event.osArhitecture.startsWith(searchValue) ||
            event.deviceSenderId.startsWith(searchValue) ||
            event.requesterDID.startsWith(searchValue) ||
            event.senderDeviceType.startsWith(searchValue) ||
            event.messageSentStatus.startsWith(searchValue) ||
            event.messageSentError.startsWith(searchValue))
        );
        console.log(apicdsFilteredData);
    }

    // Sort functions
    const [sortValue, setSortValue] = useState("senderIp");
    const [sortOrder, setSortOrder] = useState("");

    apicdsFilteredData.sort(dynamicSort(`${sortOrder}${sortValue}`));

    return (
        <main className="main-info">
            <LastUpdate />
            <ApicdsSearch updateSearchValue={setSearchValue} searchValue={searchValue} updateSortValue={setSortValue} updateSortOrder={setSortOrder} />

            <table>
                <thead>
                    <tr>
                        <th>Sender</th>
                        <th>OS</th>
                        <th>Sent Message</th>
                        <th>Date</th>
                        <th>Device</th>
                        <th>Requester DID</th>
                    </tr>
                </thead>

                <tbody>
                    {apicdsFilteredData.map(entry => {
                        let newuuid = uuidv4();
                        return (
                            <tr key={newuuid}>
                                <td><ul>
                                    <li><strong>IP: </strong>{entry.senderIp}</li>
                                    <li><strong>MAC: </strong>{entry.mac}</li>
                                </ul></td>
                                <td><ul>
                                    <li><strong>Name: </strong>{entry.osName}</li>
                                    <li><strong>Arch: </strong>{entry.osArhitecture}</li>
                                </ul></td>
                                <td><ul>
                                    <li><strong>Status: </strong>{entry.messageSentStatus}</li>
                                    <li><strong>Time: </strong>{entry.messageSentTime}</li>
                                    <li><strong>Error: </strong>{entry.messageSentError}</li>
                                </ul></td>
                                <td><ul>
                                    <li><strong>Date: </strong>{entry.date}</li>
                                    <li><strong>Hour: </strong>{entry.hour}</li>
                                </ul></td>
                                <td><ul>
                                    <li><strong>ID: </strong>{entry.deviceSenderId}</li>
                                    <li><strong>Type: </strong>{entry.senderDeviceType}</li>
                                </ul></td>
                                <td>{entry.requesterDID ? entry.requesterDID : "None"}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </main>
    )
}

export default Apicdsdata


export async function loader({ request }) {

    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    // const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    const Response = await fetch(`${baseURL}/api/apicds`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    if (!Response.ok) {
        // TODO: Handle the rerror
    }
    const responseData = await Response.json();

    responseData.forEach(event => {
        if (event["requesterDID"] === null) {
            event["requesterDID"] = "None"
        }
    })

    return responseData;
}

function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}