// Import pages
import NavBar from './NavBar/NavBar';
import MainPage from './MainPage/MainPage';
import { NotificationContainer } from 'react-notifications';


// Import CSS
import './Dashboard.css'

function Dashboard() {

    return (
        <div className='dashboard-page'>
            <NavBar />
            <MainPage />
            <NotificationContainer />
        </div>
    );
}

export default Dashboard;