import { useState } from 'react';

function ApicdsSearch(props) {
    const [formHeaderShow, setFormHeaderShow] = useState(false);
    const newSearch = (event) => {
        props.updateSearchValue(event.target.value);
    }
    const newSort = (event) => {
        props.updateSortValue(event.target.value);
    }
    const newOrder = (event) => {
        props.updateSortOrder(event.target.value);
    }


    if (formHeaderShow) {
        return (
            <div className='main-page-input-container'>
                <div className='form-header' onClick={() => setFormHeaderShow(formHeaderShow => !formHeaderShow)}>
                    <h2 className='heading-tertiary'>Filter Events</h2>
                    <span className="material-symbols-outlined"> {formHeaderShow ? "expand_more" : "expand_less"} </span>
                </div>
                <form className={`form-container`} onSubmit={(event) => event.preventDefault()}>
                    <div>
                        <label className='subheading' htmlFor='eventToSearch'>Filter Events</label>
                        <input type='text' id='eventToSearch' placeholder='Event' value={props.searchValue} onChange={newSearch}></input>
                    </div>
                    <button className='btn' type='submit' onClick={() => props.updateSearchValue("")}>Clear</button>
                </form>
                <form className={`form-container`}>
                    <div>
                        <label className='subheading' htmlFor='sortBy'>Sort By</label>
                        <select id="sortBy" onChange={newSort}>
                            <option value="senderIp">Sender IP</option>
                            <option value="mac">Sender MAC</option>
                            <option value="osName">OS Name</option>
                            <option value="osArhitecture">OS Arch</option>
                            <option value="messageSentStatus">Sent Message Status</option>
                            <option value="date">Date</option>
                            <option value="deviceSenderId">Device ID</option>
                            <option value="senderDeviceType">Device Type</option>
                            <option value="requesterDID">Requester DID</option>
                        </select>
                    </div>
                    <div>
                        <select id="sortBy" onChange={newOrder}>
                            <option value="">Ascending</option>
                            <option value="-">Descending</option>
                        </select>
                    </div>
                </form>
            </div>
        )
    } else {
        return (
            <div className='main-page-input-container'>
                <div className='form-header' onClick={() => setFormHeaderShow(formHeaderShow => !formHeaderShow)}>
                    <h2 className='heading-tertiary'>Filter Events</h2>
                    <span className="material-symbols-outlined"> {formHeaderShow ? "expand_more" : "expand_less"} </span>
                </div>
            </div>
        )
    }
}

export default ApicdsSearch;