import { Outlet } from 'react-router-dom';

import './MainPage.css'

// Import components
import HeaderNav from './HeaderNav/HeaderNav';

function MainPage() {

    return (
        <div className='main-page-container'>
            <HeaderNav />
            <Outlet />
        </div>
    )
}

export default MainPage;