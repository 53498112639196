import HomePageComponent from './HomePageComponents'
import UsefulPage from './UsefulPage'

import './HomePage.css'
import './SecantComponents/MainData.css'

import secantLogoSmall from '../../../img/secant_logo_small.png'

function HomePage() {
    const secantComponents = [
        {
            name: "TIM",
            fullName: "Threat Intelligence Module",
            description: "Collects and extracts Cyber Threat Intelligence (CTI) from several external and internal sources",
            url: "/components/tim"
        },
        {
            name: "HVA",
            fullName: "Human Vulnerability Assessment",
            description: "Analyses personality traits of an ICT ecosystem’s personnel correlating them with known types of social engineering attacks’ categories",
            url: "/components/hva"
        },
        {
            name: "TVIA",
            fullName: "Technical Vulnerability and Impact Assessment",
            description: "Performs periodic vulnerability assessments on the identified assets, producing detailed reports",
            url: "/components/tvia"
        },
        {
            name: "API/CDS",
            fullName: "Application Programming Interface – Common Data Securer",
            description: "Acts as a middleware between the SECANT platform and the medical infrastructure of any type and architectural style of device and network",
            url: "/components/apis"
        },
        {
            name: "CoCRAE",
            fullName: "Connected Organizations - Cyber Risk Assessment Engine",
            description: "Performs dynamical, continuous and near-real time cyber-physical security risk assessment",
            url: "/components/cocrae"
        },
        {
            name: "BAE",
            fullName: "Behaviour Analytics Engine",
            description: "Focuses on analysing the data generated from the network devices and the monitoring agents through the implementation of the User and Entity Behaviour Analysis",
            url: "/components/bae"
        },
    ]

    const usefulPages = [
        {
            name: "Vocabulary",
            description: "A centralized reference that defines and explains key terms, acronyms, and jargon specific to SECANT, ensuring clear communication among stakeholders",
            url: "/vocabulary"
        },
        {
            name: "Alerts",
            description: "List with all the alerts that provide real-time notifications on key metrics or events to ensure timely awareness and prompt action",
            url: "/alerts"
        },
        {
            name: "Configuration",
            description: "Configuration options for the Dashboard",
            url: "/Configuration"
        },
        {
            name: "Documentation",
            description: "Useful Links, Documents, and reports for the SECANT Dashboard, as well as the whole project",
            url: "/Documentation"
        },
    ]

    return (
        <main className='main-info'>
            <section className='home-page__header'>
                <div className='home-page-logo-container'>
                    <img src={secantLogoSmall} alt='SECANT Logo Small' className='home-page-logo' />
                </div>
                <div className='home-page-headings-container'>
                    <h1 className='heading-secondary'>SECANT Dashboard</h1>
                    <p className='subheading'>For security professionals</p>
                </div>
            </section>

            <section className='home-page__main'>
                <h3 className='heading-tertiary'>SECANT Components</h3>
                <div className='home-page__main__components grid grid--3-cols normal'>
                    {secantComponents.map(homeComponent => <HomePageComponent key={homeComponent.name} homeComponent={homeComponent} />)}
                </div>
                <h3 className='heading-tertiary'>Useful Pages</h3>
                <div className='home-page__main__components grid grid--4-cols normal'>
                    {usefulPages.map(usefulPage => <UsefulPage key={usefulPage.name} usefulPage={usefulPage} />)}
                </div>
            </section>
        </main>
    )
}

export default HomePage;