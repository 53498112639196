import { useLoaderData } from 'react-router-dom';
import { useState } from "react";
import { useEffect } from 'react';

import EventReport from "../../../../EventReports/EventReport";
import LastUpdate from '../SharedComponents/LastUpdate';
import BackButton from '../SharedComponents/BackButton';

import '../MainData.css'
import TviaReportsSearch from './TviaReportsSearch';

function TviaReports() {
    const tviaReportData = useLoaderData();
    const [hideStatus, setHideStatus] = useState({ status: true, data: null });

    const refreshStatus = localStorage.getItem("refresh");
    useEffect(() => {
        if (refreshStatus === "on") {
            const interval = setInterval(() => {
                window.location.reload(false);
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [refreshStatus]);

    // Sort functions
    let tviaReporFilteredData = tviaReportData;
    const [sortValue, setSortValue] = useState("id");
    const [sortOrder, setSortOrder] = useState("");

    tviaReporFilteredData.sort(dynamicSort(`${sortOrder}${sortValue}`));

    return (
        <div className='main-info'>
            <LastUpdate />
            <BackButton />
            <TviaReportsSearch updateSortValue={setSortValue} updateSortOrder={setSortOrder} />

            <h2 className='subheading'>TVIA Reports</h2>
            <table>
                <thead>
                    <tr>
                        <th>Report ID</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    {tviaReporFilteredData.map((item) => {
                        return (
                            <tr key={`tr-${item.id}`}>
                                <th>{item.id}</th>
                                <td>{item.createdAt}</td>
                                <td>{item.updatedAt}</td>
                                <td><span className="material-symbols-outlined open-report-button" onClick={() => {
                                    setHideStatus({ status: false, data: item });
                                }}> visibility </span> </td>
                            </tr>)
                    })}
                </tbody>
            </table>
            <EventReport hideStatus={hideStatus} setHideStatus={setHideStatus} title="TVIA Report" component="Reports" />
        </div>
    )
}

export default TviaReports

export async function loader({ request }) {
    // const testURL = request.url.split("/");
    // const protocol = testURL[0];
    // const domain = testURL[2].split(":")[0];
    //const baseURL = protocol + "//" + domain + ":8080";
    const baseURL = "https://secant.simavi.ro/api/dsbbackend"

    const DIM_TOKEN = localStorage.getItem("token");

    // Get the TVIA Reports
    const response = await fetch(`${baseURL}/api/tvia/reports`,
        {
            headers: {
                "Authorization": `${DIM_TOKEN}`,
            }
        },);
    if (!response.ok) {
        // TODO: Handle the rerror
    }
    const responseData = await response.json();

    return responseData;
};

function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}