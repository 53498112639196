import { Link } from "react-router-dom";

import BackButton from "../SecantComponents/SharedComponents/BackButton";

import './Documentation.css';

function Documentation() {
    const secantComponents = ["CoCRAE", "TVIA", "HVA", "TIM", "DIM", "BAE", "API-CDS"];

    return (
        <div className="main-info">

            <div className="documentation-container">
                <BackButton />
                <h2 className="heading-secondary">SECANT Components</h2>
                <div className="grid grid--3-cols">
                    {secantComponents.map(item => {
                        return (
                            <Link className={`center documnetation-item-container documnetation-item-container--${item}`} key={item} to={`/documentation/component?tool=${item}`}>{item}</Link>
                        )
                    })}
                </div>
                <h2 className="heading-secondary">Useful Pages</h2>
                <div className="grid grid--3-cols">
                    <Link className={`center documnetation-item-container documnetation-item-container--web`} to="https://secant-project.eu/" target="_blank" rel="noopener noreferrer">Website</Link>
                    <Link className={`center documnetation-item-container documnetation-item-container--dels`} to="https://secant-project.eu/dissemination/" target="_blank" rel="noopener noreferrer">Deliverables</Link>
                </div>
            </div>
        </div>
    );
}

export default Documentation;