import { useState } from 'react';

// Import Images and CSS
import './NavBar.css'

// Import components
import MainNav from './MainNav/MainNav';
import HeaderLogo from './Logo/HeaderLogo';
import MenuButton from './MenuButton/MenuButton';

function NavBar() {
    const [menuOpen, setMenuOpen] = useState(true);
    const menuToggleHandler = () => setMenuOpen(menuOpen => !menuOpen);

    return (
        <section className={`nav-bar-section ${menuOpen ? "nav-bar-section--open" : "nav-bar-section--closed"}`}>
            <HeaderLogo menuOpen={menuOpen} />
            <MenuButton menuOpen={menuOpen} menuToggleHandler={menuToggleHandler} />
            <MainNav menuOpen={menuOpen} />
        </section>
    )
}

export default NavBar;