import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Import pages for components
import Dashboard from './components/Dashboard';
import HomePage from './components/MainPage/MainData/HomePage';
import TviaData from './components/MainPage/MainData/SecantComponents/Tvia/TviaData';
import TviaReports, { loader as TviaReportsLoader } from './components/MainPage/MainData/SecantComponents/Tvia/TviaReports';
import TviaAssets, { loader as TviaAssetsLoader } from './components/MainPage/MainData/SecantComponents/Tvia/TviaAssets';
import TviaAssetPort, { loader as TviaAssetPortLoader } from './components/MainPage/MainData/SecantComponents/Tvia/TviaAssetPort';
import TviaAssetService, { loader as TviaAssetServiceLoader } from './components/MainPage/MainData/SecantComponents/Tvia/TviaAssetService';
import TviaAssetVul, { loader as TviaAssetVulLoader } from './components/MainPage/MainData/SecantComponents/Tvia/TviaAssetVul';
import TimData, { loader as timLoader } from './components/MainPage/MainData/SecantComponents/Tim/TimData';
import TimEvents, { loader as timEventsLoader } from './components/MainPage/MainData/SecantComponents/Tim/TimEvents';
import CocraeData, { loader as cocraeLoader } from './components/MainPage/MainData/SecantComponents/Cocrae/CocraeData';
import ApicdsData, { loader as apicdsLoader } from './components/MainPage/MainData/SecantComponents/Apiscds/Apicds';
import HvaData from './components/MainPage/MainData/SecantComponents/Hva/Hva';
import HvaReports, { loader as HvaReportsLoader } from './components/MainPage/MainData/SecantComponents/Hva/HvaReports';
import HvaCampaigns, { loader as HvaCampaignsLoader } from './components/MainPage/MainData/SecantComponents/Hva/HvaCampaigns';
import HvaDepartments, { loader as HvaDepartmentsLoader } from './components/MainPage/MainData/SecantComponents/Hva/HvaDepartments';
import BaeData, { loader as BaeLoader } from './components/MainPage/MainData/SecantComponents/Bae/Bae';

// Useful pages
import Documentation from './components/MainPage/MainData/Documentation/Documentation';
import DocumentationItem from './components/MainPage/MainData/Documentation/DocumentationItem';
import Vocabulary from './components/MainPage/MainData/Vocabulary/Vocabulary';

// Authentication
import AuthenticationPage, { action as authAction } from './pages/Authentication';
import { checkAuthLoader } from './util/auth';
import { loader as logoutLoader } from './pages/Logout'
import Configuration, { action as configAction } from './components/MainPage/MainData/Configuration/Configuration';

// Import pages for options
import ComingSoon from './components/ComingSoon/ComingSoon';

// Define the main router
const router = createBrowserRouter([
  {
    path: "",
    element: <Dashboard />,
    loader: checkAuthLoader,
    children: [
      { index: true, element: <HomePage /> },
      { path: "/components/tim", element: <TimData />, loader: timLoader },
      { path: "/components/tim/:eventId", element: <TimEvents />, loader: timEventsLoader },
      { path: "/components/cocrae", element: <CocraeData />, loader: cocraeLoader },
      { path: "/components/tvia", element: <TviaData /> },
      { path: "/components/tvia/reports", element: <TviaReports />, loader: TviaReportsLoader },
      { path: "/components/tvia/assets", element: <TviaAssets />, loader: TviaAssetsLoader },
      { path: "/components/tvia/assets/:assetId/ports", element: <TviaAssetPort />, loader: TviaAssetPortLoader },
      { path: "/components/tvia/assets/:assetId/services", element: <TviaAssetService />, loader: TviaAssetServiceLoader },
      { path: "/components/tvia/assets/:assetId/vulnerabilities", element: <TviaAssetVul />, loader: TviaAssetVulLoader },
      { path: "/components/hva", element: <HvaData /> },
      { path: "/components/hva/reports", element: <HvaReports />, loader: HvaReportsLoader },
      { path: "/components/hva/campaigns", element: <HvaCampaigns />, loader: HvaCampaignsLoader },
      { path: "/components/hva/departments", element: <HvaDepartments />, loader: HvaDepartmentsLoader },
      { path: "/components/apis", element: <ApicdsData />, loader: apicdsLoader },
      { path: "/components/bae", element: <BaeData />, loader: BaeLoader },
      { path: "/alerts", element: <ComingSoon /> },
      { path: "/vocabulary", element: <Vocabulary /> },
      { path: "/documentation", element: <Documentation /> },
      { path: "/documentation/component", element: <DocumentationItem /> },
      { path: "/configuration", element: <Configuration />, action: configAction },
      { path: "/home", element: <HomePage /> },
    ]
  },
  {
    path: "/auth",
    element: <AuthenticationPage />,
    action: authAction
  },
  {
    path: "/logout",
    loader: logoutLoader
  }
])

function App() {

  return (
    <RouterProvider router={router} />
  );
}

export default App;
