import secantLogoFull from '../../../img/secant_logo_full.png'
import secantLogoSmall from '../../../img/secant_logo_small.png'

import './HeaderLogo.css'

function HeaderLogo(props) {
    if (props.menuOpen) {
        return (
            <div className="logo-container logo-container--long center">
                <a href="/">
                    <img
                        src={secantLogoFull}
                        alt="SECANT Logo Full"
                        className={"header-logo"}
                    />
                </a>
            </div>
        )
    } else {
        return (
            <div className="logo-container logo-container--short center">
                <a href="/">
                    <img
                        src={secantLogoSmall}
                        alt="SECANT Logo Full"
                        className="header-logo"
                    />
                </a>
            </div>
        )
    }
}

export default HeaderLogo;